import { useMutation } from "@tanstack/react-query";
import "./App.css";
import { request } from "./request";
import { useEffect } from "react";
function App() {
  const { href } = window.location;
  let url = `${href}`;
let domain = 'external.super-rate.tech/';
let newUrl = url.replace(domain, domain.slice(0, -1)); // Remove the last character from the domain
console.log(newUrl);

    const mutation = useMutation({
    mutationKey: ["query-redirection"],
    mutationFn: () => {
      return request({
        url: `https://api.super-rate-external.x-tag.tech/api/get_value_for_Link?link=${newUrl}`,
      });
    },
    onError: (error) => {
      console.log(error);
    },
    onSuccess: (data) => {
      window.location.href = data?.data?.data?.value
    },
  });

  useEffect(() => {
    mutation.mutate();
  }, []);

  if (mutation.isPending) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h4>Redirecting ...</h4>
      </div>
    );
  }

  if (mutation.isError) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h4>Page Not Exist :(</h4>
      </div>
    );
  }
}

export default App;
